import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isAuth: boolean = false;
  isAdmin: any = this.isUserAdmin();
  baseUrl = environment.baseUrl;

  constructor(
    private router: Router) { }

  setUserToken(token: any) {
    localStorage.setItem('nartikToken', token);
    this.isAuth = true;
    this.checkUserAuth();
  }

  setUserObj(userObj: any) {
    localStorage.setItem('nartikObj', JSON.stringify(userObj));
  }

  isUserAdmin() {
    let userObj =  JSON.parse(JSON.parse(JSON.stringify(localStorage.getItem('nartikObj')))) || null ;
    return  userObj && userObj.role == 'owner' ? true : false
  }
  
  checkUserAuth() {
    if (this.getUserToken()) {
      this.isAuth = true;
      const userObj: any = this.getUserObj();
    }
  }

  signOut() {
    if(localStorage?.getItem('nartikToken')){
      localStorage.removeItem('nartikToken');
      
    }else{
      sessionStorage.removeItem('nartikToken');
    }
    localStorage.removeItem('nartikObj');
    localStorage.removeItem('subscribed_before')
    this.isAuth = false;
    this.router.navigate(['/auth'])
  }

  getUserToken() {  
    return localStorage.getItem('nartikToken') || sessionStorage.getItem('nartikToken');
  }
  getUserObj() {
    // return JSON.parse(localStorage.getItem('nartikObj') || '')
    if (localStorage.getItem('nartikObj')) {
      return JSON.parse(localStorage.getItem('nartikObj') || '')
    }
    return {};
  }

  isUserAuth() {
    return this.isAuth;
  }

}
