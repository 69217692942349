export const environment = {
  production: true,
  baseUrl: 'https://nartik-staging-back.ademo.net/api',

  googleClientID:'477391161953-ctfrmli5g7j3ml26kmoj2pdacv164gtu.apps.googleusercontent.com',
  googleSecret:'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',
  googleRedirectURl:'https://nartik-staging.ademo.net',

  FBAppID: '1097230088542415',
  FBAppSecret:'814235638c3e7a63f9293a35dbaffaba',

  stripeKey:'pk_test_51NywkpCewV8Cn5cnjxSrHYKwJMcDtlXGENYA4otlj3kYWH5sH3wLfKbjC39Lcr5047IfeRW0ZlW7PCfQxRkHzaLL00VlSlpR9X',

  apiKey_loqate:'WN85-CY69-AG78-ND97'

};
